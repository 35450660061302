$c-primary: #1B75BC;
$c-secandary: #1B75BC;
$dots: false;
$heading-color-overrided: #333333;
$body-color-overrided: #555555;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Gordita', sans-serif;

      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-MediumItalic.otf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Italic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-ExtraLight.ttf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  padding: 0;
  .hasError {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3px;
    color: $c-danger;
  }
}

.countryItem {
  list-style: none;
  .imageContainer {
    margin-right: 0.625rem;
    .flagImage {
      width: 2rem;
      height: 1.125rem;
    }
  }
}

:export {
  primary: var(--primary);
  secondary: var(--secondary);
  primary25: '#fff';
  primary50: lighten($c-secandary, 45%);
  gray: $c-grey;
  bodyGray: #fff;
}
