$c-primary: #1B75BC;
$c-secandary: #1B75BC;
$dots: false;
$heading-color-overrided: #333333;
$body-color-overrided: #555555;
$base-font-family: 'Roboto', sans-serif;
$font-family-heading: 'Gordita', sans-serif;

      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Roboto';
        src: url('/assets-mobile/fonts/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Medium.otf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-MediumItalic.otf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Italic.otf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Gordita';
        src: url('/assets-mobile/fonts/Gordita-LightItalic.otf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/assets-mobile/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Inter';
        src: url('/assets-mobile/fonts/Inter-ExtraLight.ttf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1rem 1.5rem;
  width: 100%;
  height: 4.8125rem;
  box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.25);
}

form legend {
  line-height: 1;
}

.buttonsWrapper {
  height: 100%;

  .buttons {
    height: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: auto;
      margin: 0 7px;
      min-width: 57%;

      &:first-child {
        text-align: left;
        min-width: 0px !important;
      }
    }
  }
}

.review {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  .header {
    color: gray;
  }

  .questionsGroups {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: 100px;

    .questionsGroup {
      border-color: #f5f5f5;
      border-style: solid;
      border-radius: 5px;
      border-width: 1px;

      .questions {
        display: flex;
        flex-direction: column;
        padding: 5px;

        .question {
          .title {
          }

          .answer {
            color: gray;
          }
        }
      }

      .edit {
        padding: 10px;
        border-top-style: solid;
        border-top-width: 1px;
        border-radius: 5px;
        border-top-color: #f5f5f5;
        text-align: right;
      }
    }

    .questionsGroup:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .importantInfo {
    padding: 10px;
  }
}
